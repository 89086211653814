import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const KeyboardShortcut = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.KeyboardShortcut;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../data/snippets/keyboard-shortcut.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Keyboard Shortcut"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-keyboard-shortcut--docs"
      />
      <CodeSnippet
        scope={{ React: React, KeyboardShortcut: KeyboardShortcut }}
        code={snippet}
        platform="react"
        gitHubLink="keyboard-shortcut"
      />
      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['string']} isRequired>
            <Text>
              The key to display. Single character keys will automatically be
              uppercased. Multi-character keys like shift, esc, etc., will
              automatically be lowercased. Pass in the following to get
              direction keys:
            </Text>
            <List type="unordered">
              <li>
                <code>up</code>
              </li>
              <li>
                <code>down</code>
              </li>
              <li>
                <code>left</code>
              </li>
              <li>
                <code>right</code>
              </li>
            </List>
          </PropListItem>

          <ClassnamePartial componentName="keyboard shortcut" />

          <EnvironmentPartial />
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial componentName="keyboard shortcut" />
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Keyboard Shortcut"
      />
    </PlatformTab>
  );
};

export default WebTab;
